import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={146}
        height={180}
        fill="none"
        {...props}
    >
        <path
            fill="#292A2E"
            d="M136.927 3.076 4.363 7.345 9.724 176.83l132.564-4.268-5.361-169.486Z"
        />
        <path
            fill="#fff"
            d="M136.927 3.076 4.363 7.345 9.724 176.83l132.564-4.268-5.361-169.486Z"
            opacity={0.58}
        />
        <path
            fill="#fff"
            d="M132.078.124.123 11.107l13.799 168.769 131.955-10.983L132.078.123Z"
        />
        <path
            fill="#000"
            d="m131.974.248 13.78 168.54-131.728 10.964L.246 11.212 131.974.248Zm.208-.248L0 11.002 13.818 180 146 168.998 132.182 0Z"
        />
        <path
            fill="#292A2E"
            d="m123.102 12.003-60.228 5.013.36 4.394 60.227-5.013-.359-4.394Z"
        />
        <path
            fill="#000"
            d="m124.079 23.956-60.23 5 .02.228 60.229-5-.019-.228ZM124.739 32.022l-60.23 5 .02.229 60.228-5-.018-.229ZM125.398 40.089l-60.23 5 .02.228 60.229-5-.019-.228ZM126.058 48.155l-60.23 5 .019.229 60.229-5-.018-.229ZM93.538 58.98l-27.05 2.245.019.23 27.05-2.246-.019-.229Z"
        />
        <path
            fill="#292A2E"
            d="M127.768 69.064 15.573 78.402l.359 4.394 112.195-9.338-.359-4.394Z"
        />
        <path
            fill="#000"
            d="m73.316 133.321-45.779 3.8.019.229 45.78-3.8-.02-.229ZM74.093 142.814l-45.78 3.8.02.229 45.778-3.8-.018-.229ZM74.869 152.307l-45.78 3.8.02.229 45.779-3.8-.02-.229ZM75.645 161.8l-45.779 3.8.019.229 45.779-3.799-.019-.23Z"
        />
        <path
            fill="#292A2E"
            d="M22.78 139.429a2.42 2.42 0 0 0 2.409-2.43 2.42 2.42 0 0 0-2.409-2.431 2.42 2.42 0 0 0-2.409 2.431 2.42 2.42 0 0 0 2.41 2.43ZM25.16 168.543a2.42 2.42 0 0 0 2.41-2.43 2.42 2.42 0 0 0-2.41-2.431 2.42 2.42 0 0 0-2.408 2.431 2.42 2.42 0 0 0 2.409 2.43ZM24.384 159.05a2.42 2.42 0 0 0 2.41-2.431 2.42 2.42 0 0 0-2.41-2.43 2.42 2.42 0 0 0-2.408 2.43c0 1.342 1.078 2.431 2.408 2.431ZM23.582 149.24a2.42 2.42 0 0 0 2.409-2.431 2.42 2.42 0 0 0-2.409-2.43 2.42 2.42 0 0 0-2.409 2.43 2.42 2.42 0 0 0 2.41 2.431Z"
        />
        <path
            fill="#000"
            d="m132.617 128.385-45.779 3.8.019.229 45.779-3.8-.019-.229ZM133.394 137.878l-45.78 3.8.02.229 45.778-3.8-.018-.229ZM134.169 147.372l-45.779 3.799.02.229 45.778-3.799-.019-.229ZM134.946 156.865l-45.78 3.8.02.229 45.778-3.8-.018-.229Z"
        />
        <path
            fill="#292A2E"
            d="M82.08 134.493a2.42 2.42 0 0 0 2.41-2.43 2.42 2.42 0 0 0-2.41-2.431 2.42 2.42 0 0 0-2.408 2.431 2.42 2.42 0 0 0 2.409 2.43ZM84.461 163.607a2.42 2.42 0 0 0 2.409-2.431 2.42 2.42 0 0 0-2.409-2.43 2.42 2.42 0 0 0-2.409 2.43 2.42 2.42 0 0 0 2.41 2.431ZM83.685 154.114a2.42 2.42 0 0 0 2.409-2.431 2.42 2.42 0 0 0-2.409-2.43 2.42 2.42 0 0 0-2.409 2.43 2.42 2.42 0 0 0 2.409 2.431ZM82.883 144.303a2.42 2.42 0 0 0 2.409-2.43 2.42 2.42 0 0 0-2.409-2.43 2.42 2.42 0 0 0-2.409 2.43 2.42 2.42 0 0 0 2.409 2.43Z"
        />
        <path
            fill="#0F5FCB"
            d="M35.404 43.46c4.38 0 7.93-3.581 7.93-8 0-4.418-3.55-8-7.93-8-4.379 0-7.929 3.582-7.929 8 0 4.419 3.55 8 7.93 8ZM55.264 55.2a23.132 23.132 0 0 1-1.568 2.095c-3.85 4.582-9.433 7.683-15.835 8.216-7.335.61-14.156-2.283-18.84-7.294 3.691-5.874 9.973-9.995 17.376-10.611 7.403-.616 14.272 2.41 18.867 7.594Z"
        />
        <path
            fill="#000"
            d="M35.976 18.874c5.76 0 11.28 2.17 15.542 6.113 4.286 3.964 6.91 9.369 7.389 15.22 1.044 12.772-8.405 24.02-21.064 25.074-.638.053-1.284.08-1.92.08-5.76 0-11.28-2.17-15.543-6.113-4.287-3.963-6.911-9.369-7.39-15.22-1.044-12.773 8.406-24.021 21.065-25.075.637-.053 1.283-.08 1.92-.08m0-.23c-.641 0-1.289.027-1.939.081-12.805 1.066-22.329 12.403-21.272 25.323C13.766 56.31 23.94 65.59 35.923 65.59c.64 0 1.288-.027 1.938-.081 12.805-1.066 22.33-12.403 21.273-25.323-1.003-12.263-11.177-21.544-23.16-21.544ZM128.837 82.15 16.64 91.463l.019.229 112.197-9.313-.019-.23ZM129.497 90.21 17.3 99.534l.019.23 112.196-9.324-.018-.228ZM130.156 98.277 17.96 107.6l.019.229 112.196-9.323-.019-.229ZM130.816 106.344l-112.197 9.323.02.228 112.196-9.322-.019-.229ZM46.328 121.493l-27.05 2.246.02.229 27.05-2.246-.02-.229Z"
        />
        <path
            fill="#0F5FCB"
            d="M15.09 2.351c1.783.236 3.03 1.906 2.865 3.712l-1.192 12.985c-.129 1.405-1.379 2.496-2.77 2.343-1.352-.15-2.344-1.37-2.218-2.742L13 5.298c.08-.862.781-1.558 1.64-1.559a1.66 1.66 0 0 1 1.647 1.817l-.86 9.37a.425.425 0 0 1-.46.387.427.427 0 0 1-.383-.465l.857-9.335c.038-.42-.231-.825-.642-.904a.804.804 0 0 0-.951.719l-1.225 13.35c-.08.866.486 1.683 1.333 1.844a1.66 1.66 0 0 0 1.958-1.487l1.2-13.067c.127-1.39-.892-2.678-2.271-2.784-1.341-.102-2.525.902-2.65 2.258l-1.028 11.21a.425.425 0 0 1-.46.387.427.427 0 0 1-.383-.465l1.028-11.21c.173-1.882 1.865-3.26 3.74-3.013Z"
        />
    </svg>
)
const Cards = memo(SvgComponent)
export default Cards
