import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={45}
        height={40}
        fill="none"
        {...props}
    >
        <path
            fill="#102137"
            d="M42.333 13.75H15.667c-1.473 0-2.667 1.175-2.667 2.625v.875h32v-.875c0-1.45-1.194-2.625-2.667-2.625ZM13 35.625c0 1.45 1.194 2.625 2.667 2.625h26.666c1.473 0 2.667-1.175 2.667-2.625V19H13v16.625ZM33.444 22.5h7.112c.488 0 .888.394.888.875s-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.481.4-.875.888-.875Zm0 3.5h7.112c.488 0 .888.394.888.875s-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.481.4-.875.888-.875Zm0 3.5h7.112c.49 0 .888.392.888.875s-.4.875-.888.875h-7.112a.882.882 0 0 1-.888-.875c0-.483.4-.875.888-.875Zm-10.666-7c1.964 0 3.555 1.567 3.555 3.5s-1.591 3.5-3.555 3.5c-1.964 0-3.556-1.567-3.556-3.5s1.595-3.5 3.556-3.5Zm-3.556 8.75h7.111c1.473 0 2.667 1.175 2.667 2.625a.882.882 0 0 1-.889.875H17.444a.883.883 0 0 1-.888-.875c0-1.45 1.193-2.625 2.666-2.625Z"
        />
        <path
            fill="#FFC800"
            d="M14.927 6.42a1.094 1.094 0 0 1 1.788.612l.953 4.764 4.16 2.513a1.095 1.095 0 0 1-.03 1.89l-4.238 2.378-1.103 4.732a1.094 1.094 0 0 1-1.807.556l-3.572-3.296-4.842.413a1.094 1.094 0 0 1-1.086-1.547l2.03-4.414-1.888-4.477a1.094 1.094 0 0 1 1.134-1.512l4.827.566 3.674-3.179Z"
        />
    </svg>
)
const NewCards = memo(SvgComponent)
export default NewCards
