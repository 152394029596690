import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Box, Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InputField from "../../components/InputField"
import { BsCurrencyDollar } from "react-icons/bs";
import { message, Switch } from "antd";
import BlueButton from '../../components/common/Buttons/BlueButton';
import WhiteButton from '../../components/common/Buttons/WhiteButton';
import { emailRegex } from '../../configs/validation';
import axios from 'axios';
import { API_URL } from "../../utils/constants";


const CustomBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    border: '1px solid #ced4da',
    borderRadius: '8px',
    padding: '8px 8px 8px',
    minHeight: '40px',
    backgroundColor: '#fff',
    '&:focus-within': {
        borderColor: '#88939D',
    },
}));

const Label = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    top: '-14px',
    left: '12px',
    backgroundColor: '#fff',
    padding: '0 4px',
    fontSize: '14px',
    color: '#172B4D',
    fontWeight: '600',
    zIndex: 1,
}));

const NewCard = ({ addNewCard, onClose, setNewMail, newMail }) => {
    const [emails, setEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [cardData, setCardData] = useState({
        count: '',
        emails: emails,
        message: '',
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const adjustedValue = name === 'count' && Number(value) > 20 ? 20 : value;
        setCardData({ ...cardData, [name]: adjustedValue });
        if (errors[name]) {
            setErrors({ ...errors, [name]: null }); // Clear error if field is updated
        }
    };

    const handleClose = () => {
        setEmails([]);
        setCardData({
            count: '',
            emails: [],
            message: '',
        });
        setErrors({});
        onClose();
    };

    const validateFields = () => {
        const newErrors = {};

        // Validate number of cards
        if (!cardData?.count) {
            newErrors.count = 'Number of cards is required.';
        } else if (isNaN(cardData?.count) || cardData?.count <= 0 || cardData?.count > 20) {
            newErrors.count = 'Number of cards must be between 1 and 20.';
        }

        // Validate emails
        if (emails?.length === 0) {
            newErrors.emails = 'At least one email is required.';
        }

        //Validate message
        if (!cardData?.message?.trim()) {
            newErrors.message = 'Message is required.';
        } else if (cardData?.message?.length < 50) {
            newErrors.message = 'Message must be at least 50 characters long.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {
        if (!validateFields()) {
            console.log('Validation failed:', errors);
            return;
        }

        // Submit data if validation passes
        const dataToSubmit = {
            count: cardData?.count,
            message: cardData?.message,
            emails: emails,
        };
        // handel add api

        sendEmail(dataToSubmit)

    };

    const sendEmail = async (dataToSubmit) => {
        setLoading(true);
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_URL}/Physical-Card`,
            headers: {
                "Content-Type": "application/json",
            },
            data: dataToSubmit,
        };
        axios
            .request(config)
            .then((response) => {
                message.success("The email was sent successfully.");
                setNewMail(!newMail)
                setLoading(false);
                handleClose();

            })
            .catch((error) => {
                console.log("Error Get card: ", error);
                // message.error("Request failed with status code 546,Reduce cards number and try again");
                setErrors(prevErrors => ({
                    ...prevErrors,
                    count: "Request failed.Please Reduce the card number and try again."
                }));
                setLoading(false);
                // handleClose();

            });
    }

    const handleEmailChange = (e) => {
        setCurrentEmail(e.target.value);
    };

    const handleAddEmail = (e) => {
        if (e.key === 'Enter' && currentEmail) {
            if (emailRegex.test(currentEmail)) {
                setEmails([...emails, currentEmail]);
                setCurrentEmail('');
                if (errors.emails) {
                    setErrors({ ...errors, emails: null });
                }
            } else {
                alert('Please enter a valid email address');
            }
            e.preventDefault();
        }
    };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    return (
        <Dialog
            open={addNewCard}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: '12px',
                    padding: '5px',
                    width: { xs: '90%', sm: '70%', md: '50%' },
                    margin: { xs: '10px', sm: 'auto' },
                },
            }}
            disableEnforceFocus
            disableAutoFocus
        >
            <DialogTitle
                sx={{
                    color: '#172B4D',
                    fontWeight: '800',
                    fontSize: '18px',
                    lineHeight: '27px',
                }}
            >
                Generate New Cards
            </DialogTitle>
            <DialogContent>
                <InputField
                    id="count"
                    label="Number of Cards"
                    name="count"
                    value={cardData?.count}
                    placeholder="Please enter number of cards to print."
                    onChange={(e) => {
                        const value = e.target.value;
                        if (!isNaN(value) && Number(value) >= 0 && Number.isInteger(Number(value))) {
                            handleChange(e);
                        }
                    }}
                    required
                    sx={{
                        marginTop: '5px',
                        fontWeight: '500',
                        fontSize: { xs: '14px', sm: '16px' },
                        lineHeight: '25px',
                        width: { xs: '100%', sm: '100%' },
                    }}
                />
                {errors?.count && (
                    <Typography sx={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>
                        {errors.count}
                    </Typography>
                )}

                <Typography
                    sx={{
                        color: '#172B4D',
                        fontWeight: '800',
                        fontSize: '16px',
                        lineHeight: '27px',
                        marginTop: '15px',

                    }}
                >
                    Share New Cards By Email
                </Typography>
                <Box sx={{ position: 'relative', marginTop: '20px' }}>
                    <Label
                        sx={{
                            color: '#172B4D',
                            fontWeight: '600',
                            fontSize: '14px',
                            marginTop: '4px',
                        }}
                    >
                        Send New Card By Email *
                    </Label>

                    <CustomBox>
                        {emails?.map((email, index) => (
                            <Chip
                                key={index}
                                label={email}
                                onDelete={() => handleDeleteEmail(email)}
                                sx={{
                                    margin: '4px',
                                    backgroundColor: '#E1E9FF',
                                    color: '#172B4D',
                                    fontWeight: '500',
                                }}
                            />
                        ))}
                        <TextField
                            value={currentEmail}
                            onChange={handleEmailChange}
                            onKeyPress={handleAddEmail}
                            placeholder="Type an email and press Enter."
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            sx={{
                                flex: 1,
                                margin: '4px',
                                minWidth: '240px',
                            }}
                        />
                    </CustomBox>
                </Box>
                {errors?.emails && (
                    <Typography sx={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>
                        {errors.emails}
                    </Typography>
                )}

                <InputField
                    id="message"
                    label="Message"
                    name="message"
                    value={cardData?.message}
                    placeholder="Please enter your Message."
                    onChange={handleChange}
                    multiline
                    required
                    rows={4}
                    sx={{
                        fontWeight: '500',
                        fontSize: { xs: '14px', sm: '16px' },
                        lineHeight: '25px',
                        marginTop: '30px',
                    }}
                />
                {errors?.message && (
                    <Typography sx={{ color: 'red', fontSize: '13px', marginTop: '5px' }}>
                        {errors.message}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: { xs: '100%', sm: '70%', md: '50%' },
                        marginRight: '15px',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: { xs: '10px', sm: '0' },
                        marginBottom: '5px',
                    }}
                >
                    <WhiteButton onClick={handleClose}>Cancel</WhiteButton>
                    <BlueButton onClick={handleSave}>{loading ? "Sending..." : "Send"}</BlueButton>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default NewCard;

