import React, { useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputField from "../../components/InputField";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import supabase from "../../configs/supabase";
import { useNavigate } from 'react-router-dom';
import { message } from "antd";
import loginImg from "../../assets/images/loginImg.png"
import { IoIosArrowBack } from "react-icons/io";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { useSearchParams } from 'react-router-dom';
const validationSchema = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const CreateNewPassword = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const handleSubmitNewPassword = async (values, actions) => {

        // Assuming the user's access token is available in the URL or session, Supabase can reset the password
        const { error } = await supabase.auth.updateUser({
            password: values.password,
            access_token: token,
        });

        if (error) {
            actions.setFieldError('password', error.message);
            console.log(error);

            message.error("Have a failed to reset password. Please try again");
        } else {
            message.success('Your password has been updated successfully.');
            navigate('/logIn');  // Redirect user to the login page after successful password reset
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "normal",
                },
                justifyContent: "center",
                height: "100vh",
                gap: "50px"
            }}
        >
            {/* Left Side - Image */}
            <Box
                sx={{
                    flex: 1,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                    },
                    justifyContent: "end",
                    alignItems: "center",
                }}
            >
                <img
                    src={loginImg}
                    alt="App Showcase"
                    style={{
                        width: "94%",
                        // maxWidth: "470px",
                        height: "95%",
                        objectFit: "cover",
                        borderRadius: "20px"

                    }}
                />
            </Box>

            {/* Right Side - Login Form */}
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "start",
                    },
                    justifyContent: "center",
                    padding: "3rem",
                }}
            >
                <Button
                    onClick={() => { navigate('/login') }}
                    sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "40px",
                        marginBottom: "1rem",
                        color: "#16151C",
                        marginLeft: "-15px",
                        borderRadius: "25px"
                    }}
                >
                    <IoIosArrowBack size={22} style={{ paddingRight: "5px" }} /> Back
                </Button>
                <Box sx={{ maxWidth: "400px", width: "100%", textAlign: "start" }}>
                    <Box sx={{ textAlign: "start", mb: 3 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: "800",
                                fontSize: "30px",
                                lineHeight: "40px",
                                color: "#16151C",
                            }}
                        >
                            Create New Password
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#6E6D73",
                                fontWeight: "300",
                                fontSize: "16px",
                                lineHeight: "25px",
                                mt: 1,
                            }}
                        >
                            Your new password must be different from the
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "#6E6D73",
                                fontWeight: "300",
                                fontSize: "16px",
                                lineHeight: "25px",

                            }}
                        >
                            previously used password.
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={{ password: "", confirmPassword: "" }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmitNewPassword}
                    >
                        {({ errors, touched, values, handleChange, handleBlur }) => (
                            <Form style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                {/* Password Field */}
                                <InputField
                                    id="password"
                                    label="Password"
                                    type={showPassword ? "text" : "password"}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    required
                                    Adornment={
                                        <IconButton onClick={handleClickShowPassword} edge="end" sx={{ mr: 1 }}>
                                            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                        </IconButton>
                                    }
                                />

                                {/* Confirm Password Field */}
                                <InputField
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    required
                                    Adornment={
                                        <IconButton onClick={handleClickShowConfirmPassword} edge="end" sx={{ mr: 1 }}>
                                            {showConfirmPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                        </IconButton>
                                    }
                                />

                                {/* Submit Button */}
                                <BlueButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        width: "100%"
                                    }}
                                >
                                    Reset Password
                                </BlueButton>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Box>
        </Box>

    );
};

export default CreateNewPassword;
