import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={45}
        height={38}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#102137"
                d="M41.796 11.583H15.13c-1.472 0-2.666 1.175-2.666 2.625v.875h32v-.875c0-1.45-1.195-2.625-2.667-2.625ZM12.463 33.458c0 1.449 1.194 2.625 2.666 2.625h26.667c1.473 0 2.667-1.175 2.667-2.625V16.833h-32v16.625Zm20.444-13.125h7.111c.49 0 .89.394.89.875s-.4.875-.89.875h-7.11a.885.885 0 0 1-.89-.875c0-.481.4-.875.89-.875Zm0 3.5h7.111c.49 0 .89.394.89.875s-.4.875-.89.875h-7.11a.885.885 0 0 1-.89-.875c0-.481.4-.875.89-.875Zm0 3.5h7.111c.491 0 .89.392.89.875s-.4.875-.89.875h-7.11a.882.882 0 0 1-.89-.875c0-.483.4-.875.89-.875Zm-10.666-7c1.963 0 3.555 1.567 3.555 3.5 0 1.932-1.592 3.5-3.555 3.5-1.964 0-3.556-1.568-3.556-3.5 0-1.933 1.594-3.5 3.556-3.5Zm-3.556 8.75h7.111c1.473 0 2.667 1.175 2.667 2.625a.882.882 0 0 1-.89.875H16.908a.883.883 0 0 1-.889-.875c0-1.45 1.194-2.625 2.667-2.625Z"
            />
        </g>
        <path
            fill="#0F5FCB"
            d="M11.375 18.375v-7h7v7h-7Zm0-8.75v-7h7v7h-7Zm-8.75 0v-7h7v7h-7Zm0 8.75v-7h7v7h-7Z"
        />
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M12.463 9.833h32v28h-32z" />
            </clipPath>
        </defs>
    </svg>
)


const AllCards = memo(SvgComponent)
export default AllCards
