import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0F5FCB",
      darkMain: "#0D52B2",
      deepBlue: "#05172E",
      blueGrayBg: "#f5f7f8",
      skyBlue: "#B8D6FF26",
      lightShadeOfBlue: "#E5F0FF"
    },
    secondary: {
      main: "#172B4D",
      babyBlue: "#C0EAFF"
    },
    text: {
      lightBlack: "#16151C",
      grayLavender: "#A2A1A8",
      coolGray: "#88939D",
      lavenderGray: "#BBC1CE",
      steelBlue: "#7A869A",
      lightGray: "#eeeeee",
      whiteSmoke: "#F5F5F5"
    },
    status: {
      error: "#D50000",
    },
    basic: {
      white: "#fff",
      black: "#000"
    },
    hover: {
      lightLavenderGray: "#E6E9F2"
    },
    shadow: {
      blackOpacity: "rgba(0, 0, 0, 0.1)"
    }
  },
  typography: {
    fontFamily: "TT Drugs Trial",
    textTransform: "capitalize",
  },
});

export default theme;
