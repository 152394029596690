import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, Typography, Grid, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../../components/common/table/DynamicTable";
import { useState } from "react";
import { message, Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import DynamicFilter from "../../components/common/filter/DynamicFilter"
import AddButton from '../../components/common/Buttons/AddButton';
import axios from "axios";
import { API_URL } from "../../utils/constants";
import theme from '../../configs/theme';
import { useDispatch, useSelector } from "react-redux";
import { setUsersCounters, setUsersData } from '../../redux/features/appStateSlice';
import deletee from "../../assets/images/delete.png";
import allCards from "../../assets/images/icons/users/allUsers.png";
import activeUsers from "../../assets/images/icons/users/activeUsers.png";
import disabledAccounts from "../../assets/images/icons/users/disabledAccounts.png";
import { formattedDate } from '../../configs/formate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

function Users() {

    const dispatch = useDispatch();
    const usersData = useSelector((state) => state.appState.users);
    const usersCounters = useSelector((state) => state.appState.usersCounters);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(usersData);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchWord, setSearchWord] = useState("");
    const currentPage = useRef(1);
    const currentLimit = useRef(5);
    const [userCount, setUserCount] = useState(0);
    const [counters, setCounters] = useState(usersCounters);
    const [updatingRowId, setUpdatingRowId] = useState(null);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [deletedItemId, setDeletedItemId] = useState();
    const subscriptionTypes = useSelector((state) => state.appState.subTypes);
    const [filters, setFilters] = useState({
        subscriptionType: null,
        accStatus: null,
        dateOfRegistration: null,
        numberOfProfiles: null,
    });
    const items = [
        { title: 'All Users', value: counters?.totalUsers, icon: <img src={allCards} alt="" style={{ width: "60px", height: "60px" }} /> },
        { title: 'Active Users', value: counters?.activeUsers, icon: <img src={activeUsers} alt="" style={{ width: "60px", height: "60px" }} /> },
        { title: 'Disabled Accounts', value: counters?.inactiveUsers, icon: <img src={disabledAccounts} alt="" style={{ width: "60px", height: "60px" }} /> },
        { title: 'Subscription Distribution', value: counters?.subscriptionUsers }
    ];

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
        getUsers()
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setPage(1);
        setRowsPerPage(rows);
        getUsers()
    };

    const handleClearFilter = () => {
        handleFilterChange(null)
    };

    const handleFilterChange = (data) => {

        if (data === null) {
            setFilters({
                subscriptionType: null,
                accStatus: null,
                dateOfRegistration: null,
                numberOfProfiles: null,
            });
        } else {


            setFilters((prevFilters) => ({
                ...prevFilters,
                subscriptionType: data?.subscriptionType || prevFilters.subscriptionType,
                accStatus: data?.profileStatus || prevFilters.accStatus,
                numberOfProfiles: data?.numberOfProfiles || prevFilters.numberOfProfiles,
                dateOfRegistration: data?.dateOfRegistration ? dayjs(data?.dateOfRegistration).format('YYYY-MM-DD') : prevFilters.dateOfRegistration
            }));
        }

    };


    const getUserCounters = async () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/user/statics`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const counters = parsedData?.data;
                setCounters(counters)
                dispatch(setUsersCounters(counters));
                setLoading(false);
            })
            .catch((error) => {
                console.log("Error Get data: ", error);
                setLoading(false);
            });
    }




    const columns = [
        {
            field: "full_name",
            headerName: "Name",
            flex: 1,
            sort: true,
            minWidth: "100px",
            editable: true,
            style: styles?.coulmn,
            align: "left",
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            sort: true,
            minWidth: "100px",
            editable: true,
            style: styles?.coulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
        },
        {
            field: "cards[0].count",
            headerName: "Profiles",
            minWidth: "50px",
            flex: 1,
            sort: true,
            style: styles?.coulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    {params?.row?.cards[0]?.count <= 0 ? (
                        <span>_</span>
                    ) : (
                        <span>{params?.row?.cards[0]?.count}</span>
                    )}
                </Box>
            ),
        },
        {
            field: "subscription.subscription_type.name",
            headerName: "Subscription Type",
            minWidth: "180px",
            flex: 1,
            sort: true,
            style: styles?.coulmn,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    {(params.row.subscription) && (params.row.subscription.subscription_type) && (params.row.subscription.subscription_type.name) ? (
                        <span>{params.row.subscription.subscription_type.name}</span>
                    ) : (
                        <span>_</span>
                    )}
                </Box>
            ),
        },
        {
            field: "created_at",
            headerName: "Creation Date",
            flex: 1,
            style: styles?.coulmn,
            minWidth: "140px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    <span>{formattedDate(params.row.created_at)}</span>
                </Box>
            ),
        },
        {
            field: "renew_date",
            headerName: "Renew Date",
            flex: 1,
            style: styles?.coulmn,
            minWidth: "140px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    {(params.row.subscription) && (params.row.subscription.renew_date) ? (
                        <span>{formattedDate(params.row.subscription.renew_date)}</span>
                    ) : (
                        <span>_</span>
                    )}
                </Box>
            ),
        },

        {
            field: "is_enabled",
            sort: true,
            headerName: "Enable/Disable",
            headerClassName: "super-app-theme--header",
            minWidth: "50px",
            renderCell: (params) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Switch
                        style={{
                            backgroundColor: params?.row?.is_enabled ? "#0F5FCB" : "",
                        }}
                        checked={params?.row?.is_enabled}
                        onChange={(value) => {
                            handleEnableDisableChange(params?.row?.id, value)
                        }}
                        loading={updatingRowId === params?.row?.id}
                        disabled={updatingRowId === params?.row?.id}
                    />
                </div>
            ),
        },
        {
            field: "action",
            headerName: "Action",
            align: "center",
            style: styles?.coulmn,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {/* <LuEye size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }} />
                    <CiEdit size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }} /> */}
                    <RiDeleteBin5Line size="22.5px" style={{
                        color: "#16151C", cursor: "pointer",
                    }}
                        onClick={() => {
                            setDeleteConfirmationDialogOpen(true)
                            setDeletedItemId(params?.row?.id)
                        }}
                    />
                </Box>
            ),
        },

    ];

    const filterData = {

        'Subscription Type': [
            {
                name: 'subscriptionType',
                gridWidth: { xs: 12, sm: 12 },
                list: subscriptionTypes?.map(type => ({
                    label: type.name,
                    value: type.id
                })),
                // list: [
                //     { label: 'Monthly Plan', value: 'monthly' },
                //     { label: 'Yearly Plan', value: 'yearly' },
                // ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Account Status': [
            {
                name: 'profileStatus',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false },
                ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Date of Registration': [
            {
                name: 'dateOfRegistration',
                gridWidth: { xs: 12, sm: 12 },
                type: 'date',
                placeholder: 'Select Date',
            },
        ],
        'Number of Profiles': [
            {
                name: 'numberOfProfiles',
                gridWidth: { xs: 12 },
                type: 'number',
                output: 'number',
                placeholder: 'Enter Number Of Profiles',
            },
        ],

    };


    const getParams = () => {

        const params = {
            page: currentPage.current,
            limit: currentLimit.current,
            search_full_name: searchWord,
            search_email: searchWord,
            "filter_cards.count": filters?.numberOfProfiles,
            filter_is_enabled: filters?.accStatus,
            "filter_subscription.subscription_type.id": filters?.subscriptionType,
            date_created_at: filters?.dateOfRegistration,
            sort_created_at: "desc"
        };
        return params;
    };
    const getUsers = async () => {
        setLoading(true);
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${API_URL}/user`,
            headers: {
                "Content-Type": "application/json",
            },
            params: getParams(),
        };
        axios
            .request(config)
            .then((response) => {
                const parsedData = JSON.parse(response?.data);
                const users = parsedData?.data?.data;
                setUserCount(parsedData?.data?.count)
                dispatch(setUsersData(users));
                setUsers(users);
                setLoading(false);
                getUserCounters()

            })
            .catch((error) => {
                console.log("Error Get card: ", error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getUsers()
    }, [rowsPerPage, searchWord, filters])

    const handleEnableDisableChange = async (id, value) => {
        setUpdatingRowId(id);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/user/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { is_enabled: value }
        };
        axios
            .request(config)
            .then((response) => {
                getUsers()
                setUpdatingRowId(null);
            })
            .catch((error) => {
                console.log("Error update data: ", error);
            });
    }


    const deleteUser = async (id) => {
        setLoading(true);
        let config = {
            method: "DELETE",
            maxBodyLength: Infinity,
            url: `${API_URL}/user/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                getUsers()
                setLoading(false);
                setDeleteConfirmationDialogOpen(false)
                message.success("The user was deleted successfully.");

            })
            .catch((error) => {
                setLoading(false);
                console.log("Error update data: ", error);
            });
    }

    const handleDeleteConfirmation = () => {
        deleteUser(deletedItemId);
    };

    return (
        <Box>
            {/* topside */}
            <Box>
                {/* titel and add btn */}
                <Box

                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    style={{
                        display: "flex",
                        gap: "14px",
                        marginTop: "-130px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "6px",
                    }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        gap: "14px",
                    }}>
                        <Typography
                            variant='h5'
                            style={{
                                color: theme.palette.basic.white,
                                fontWeight: "700",
                                lineHeight: "2.1rem",
                                marginLeft: "10px"
                            }}>
                            All Users
                        </Typography>
                    </Box>
                    {/* <AddButton
                    // onClick={() => {}}
                    >
                        +{" "}{"Add New User"}
                    </AddButton> */}

                </Box>
            </Box>
            {/* cards */}
            <Box
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: "8px",
                    gap: 2,
                    p: 1
                }}
            >
                {items?.map((item, index) => (
                    <Box key={index} xs={12} sm={6} md={3} lg={3} sx={{ width: { xs: '100%', sm: '48%', md: '23%' } }}>
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: item.icon ? "13.5px 20px" : "16.5px 20px",
                                boxShadow: `${theme.palette.shadow.blackOpacity} 0px 4px 4px`,
                                borderRadius: "8px",
                                gap: 2,
                            }}
                        >
                            <CardContent
                                sx={{
                                    width: item.icon ? '70%' : '100%',
                                    textAlign: 'left',
                                    p: 0
                                }}
                            >
                                <Typography variant='body2' sx={{ color: theme.palette.secondary.main, fontWeight: 800, lineHeight: "1.125rem" }} component="div">
                                    {item.title}
                                </Typography>
                                <Typography variant='h5' sx={{ color: theme.palette.primary.main, fontWeight: 800, lineHeight: "30.24px", mt: 1, ml: .5 }} component="div">
                                    {item.value}
                                </Typography>
                            </CardContent>

                            {item.icon ? (
                                <Box
                                // sx={{ ...styles.hoverEffectStyle }}
                                >
                                    {item.icon}
                                </Box>
                            ) : (
                                <Box>
                                </Box>
                            )}
                        </Card>
                    </Box>
                ))}
            </Box>
            {/* search and filter */}
            <Grid container
                spacing={1}
                style={{
                    paddingLeft: "10px",
                    marginTop: "10px",
                    marginLeft: "0",
                    backgroundColor: theme.palette.basic.white,
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"

                }}>
                <Grid item xs={12} md={3} display={"flex"} alignItems={"center"}
                >
                    <Typography variant='h6'
                        style={{
                            color: theme.palette.secondary.main,
                            fontWeight: "700",
                        }}> All Users
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9} display={"flex"} gap={"9px"} alignItems={"end"} justifyContent={"end"} style={{ padding: "15px 10px 15px 0" }}>

                    <Grid item xs={12} md={5}  >
                        <InputField
                            id="Search"
                            placeholder="Search by Name or Email."
                            type="text"
                            onChange={(e) => setSearchWord(e.target.value)}
                            startAdornment={<FiSearch size={24} color="#88939D" />}
                            variant="outlined"
                            sx={{
                                fontWeight: "500",
                                fontSize: "17px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} style={{ maxWidth: "fit-content" }}>
                        <DynamicFilter
                            width={'450px'}
                            onApply={(data) => handleFilterChange(data)}
                            filterData={filterData}
                            mainColor={colorConfigs.sidebar.active}
                            inputsStyle={{ borderRadius: '10px', height: '45px', fontSize: '16px' }}
                            onClear={handleClearFilter}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <DynamicTable
                columns={columns}
                data={users}
                count={userCount}
                page={page}
                rowsPerPage={rowsPerPage}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pagetype="users"
            />
            {/* delete */}
            <Dialog
                style={{ zIndex: 10, borderRadius: "30px" }}
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent style={{ padding: "30px" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src={deletee} alt="" />
                    </div>
                    <DialogContentText sx={{ textAlign: "center" }}>
                        <Typography component="span" variant="h6" style={{ fontWeight: "bold", color: "black", textAlign: "center" }}>
                            Delete User
                        </Typography>
                    </DialogContentText>
                    <Typography component="span">
                        Are you sure you want to delete this User?
                    </Typography>
                </DialogContent>

                <DialogActions
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginBottom: "20px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                    }}
                >
                    <Button
                        variant="outlined"
                        style={{
                            color: "black",
                            borderColor: "lightgray",
                            width: "100%",
                        }}
                        onClick={() => setDeleteConfirmationDialogOpen(false)}
                        disabled={loading}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            background: "var(--Error, #FD4343)",
                            color: "white",
                            width: "100%",
                        }}
                        onClick={() => handleDeleteConfirmation()}
                        disabled={loading}
                    >
                        {loading ? "Deleting..." : "Yes, Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    );
}


const styles = {
    coulmn: {
        color: "#172B4D",
        fontSize: "14.5px",
        fontWeight: "600",
        lineHeight: "20px",
    },
    hoverEffectStyle: {
        cursor: "pointer",
        borderRadius: 25,
        height: '60px',
        width: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
            transform: 'scale(1.2)',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
        }
    }
}
export default Users;
