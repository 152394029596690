import React, { useState } from "react";
import {
    TextField,
    InputAdornment,
    Box,
    FormControl,
    Tooltip,
    FormHelperText,
    Stack,
    IconButton,
    useTheme,
} from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function InputField({
    id,
    label,
    value,
    onChange,
    error,
    onBlur,
    helperText,
    required = false,
    info = false,
    marginTop = "1px",
    marginBottom = "1px",
    Adornment = null,
    startAdornment = null,
    disabled = false,
    maxLength,
    multiline = false,
    rows = 1,
    onFocus,
    onKeyPress,
    ariaLabel,
    variant = "outlined",
    type = "text",
    autoFocus = false,
    pattern,
    readOnly = false,
    showPasswordToggle = false,
    size = "medium",
    defaultValue,
    sx = {},
    placeholder = ""
}) {
    const [showPassword, setShowPassword] = useState(false);
    const theme = useTheme();
    return (
        <Box>
            <FormControl
                fullWidth
                variant={variant}
                sx={{
                    marginTop: marginTop,
                    marginBottom: marginBottom,
                }}
                error={error}
            >
                <TextField
                    id={id}
                    name={id}
                    label={label || ""}
                    placeholder={placeholder || ""}
                    required={required}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    onKeyDown={onKeyPress}
                    error={error}
                    variant={variant}
                    fullWidth
                    disabled={disabled}
                    multiline={multiline}
                    rows={rows}
                    autoFocus={autoFocus}
                    size={size}
                    defaultValue={defaultValue}
                    inputProps={{
                        maxLength: maxLength,
                        pattern: pattern,
                        readOnly: readOnly,
                        'aria-label': ariaLabel,
                        'aria-describedby': helperText && `${id}-helper-text`,
                    }}
                    type={showPasswordToggle ? (showPassword ? "text" : "password") : type}
                    InputProps={{
                        startAdornment: startAdornment ? (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ) : null,
                        endAdornment: (Adornment || showPasswordToggle) && (
                            <InputAdornment position="end">
                                {Adornment}
                                {showPasswordToggle && (
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }}

                    InputLabelProps={{
                        shrink: placeholder && placeholder !== "" ? true : undefined,
                        sx: {
                            fontSize: "18px",
                        },
                    }}
                    sx={{

                        "& .MuiInputBase-input": {
                            fontWeight: sx.fontWeight || "600",
                            fontSize: sx.fontSize || "16px",
                            lineHeight: sx.lineHeight || "24px",
                            color: theme.palette.text.lightBlack,
                            height: id === "Search" ? "11px" : "",
                        },
                        "& .MuiInputBase-input::placeholder": {
                            color: theme.palette.text.coolGray,
                            opacity: 1,
                        },
                        "& .MuiOutlinedInput-root": {
                            paddingRight: 0,
                            borderRadius: "10px",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.text.coolGray,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: theme.palette.text.lavenderGray,
                            },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.text.lavenderGray,
                        },
                        ...(id !== "email" && id !== "password" && id !== "confirmPassword"
                            ? {
                                "& .MuiInputLabel-root": {
                                    color: "#172B4D",
                                    fontWeight: "bold",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: "#172B4D",
                                },
                            }
                            : {
                                "& .MuiInputLabel-root": {
                                    color: theme.palette.text.coolGray,
                                    fontWeight: "400",
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: theme.palette.text.coolGray,
                                },
                            }),
                        ...sx,
                    }}
                />

                {helperText && (
                    <Stack direction="column">
                        <FormHelperText
                            id={`${id}-helper-text`}
                            sx={{
                                margin: "4px 0 0 0",
                                textAlign: "left",
                                position: "absolute",
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                            }}
                        >
                            <Tooltip title={helperText} arrow interactive>
                                <span>{helperText}</span>
                            </Tooltip>
                        </FormHelperText>
                        <Box sx={{ height: "10px" }} />
                    </Stack>
                )}
            </FormControl>
        </Box>
    );
}

export default InputField;
