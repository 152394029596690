import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography,
    Grid,
    IconButton,
} from "@mui/material";
import theme from "../..//configs/theme";
import { IoMdMail } from "react-icons/io";
import { FaCalendar } from "react-icons/fa6";
import starCard from "../../assets/images/cards/starCard.png";
import loadingImg from "../../assets/images/loadingImg.png"
import { ImLink } from "react-icons/im";
import { formattedDate } from "../../configs/formate"
import { IoMdClose } from "react-icons/io";

const ViewCard = ({ openViewCard, onClose, data, profilesCount }) => {


    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog
            open={openViewCard}
            onClose={handleCancel}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: '800',
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                Card Details
                <IconButton
                    onClick={handleCancel}
                    sx={{
                        color: "#000",
                        borderRadius: "50%",
                        padding: "8px",
                        transition: "background-color 0.3s",
                        "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                    }}
                >
                    <IoMdClose size={24} />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {(data && data?.user) ? (
                    <Grid container sx={{ gap: 3, }}>
                        {/* User Info: Image, Name, and Email */}
                        <Grid item xs={12} md={12} container
                            sx={{
                                backgroundColor: theme.palette.primary.skyBlue,
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "12px",
                                p: 1
                            }}
                        >
                            {/* User Image */}
                            <Grid
                                item
                                xs={12}
                                sm={2.5}
                                md={2.5}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    ml: -1,
                                    mr: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 85,
                                        height: 85,
                                        borderRadius: "4px",
                                        overflow: "hidden",
                                        border: "2px solid #ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",

                                    }}
                                >
                                    <img
                                        style={{
                                            width: 85,
                                            height: 85,
                                            borderRadius: "4px",
                                        }}
                                        src={data?.user?.profile_picture && data?.user?.profile_picture?.length > 10 ? data?.user?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                        alt=""
                                    />
                                </Box>
                            </Grid>

                            {/* User Name and Email */}
                            <Grid item xs={12} md={5.5} sm={5.5}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: { xs: "center", sm: "start" },
                                    gap: "8px"

                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        textAlign: { xs: "center", sm: "left" },
                                    }}
                                >
                                    {data?.user?.full_name?.replace(/\b\w/g, (char) => char.toUpperCase())}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: { xs: "center", sm: "left" },
                                        display: "flex",
                                        justifyContent: { xs: "center", sm: "left" },
                                        alignItems: "start",
                                        gap: "8px",
                                        fontWeight: "700",
                                        fontSize: "12"
                                    }}
                                >
                                    <IoMdMail color={theme.palette.primary.main} size={20} />
                                    {data?.user?.email}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        textAlign: { xs: "center", sm: "left" },
                                        display: "flex",
                                        justifyContent: { xs: "center", sm: "left" },
                                        alignItems: "start",
                                        gap: "8px",
                                        fontWeight: "700",
                                        fontSize: "12"
                                    }}
                                >
                                    <FaCalendar color={theme.palette.primary.main} size={20} />
                                    Created at : {formattedDate(data?.user?.created_at)}
                                </Typography>
                            </Grid>
                            {/* sub type */}
                            {data?.user?.subscription?.subscription_id?.name && (
                                <>
                                    <Grid item xs={12} md={4} sm={4}
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "start",
                                            alignItems: { xs: "center", sm: "start" },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: theme.palette.primary.lightShadeOfBlue,
                                                color: theme.palette.primary.main,
                                                fontWeight: "700",
                                                lineHeight: "15px",
                                                fontSize: "13px",
                                                textAlign: "center",
                                                borderRadius: "20px",
                                                padding: "8px 0px",
                                                minWidth: "100%",

                                            }}
                                        >
                                            {data?.user?.subscription?.subscription_id?.name} Subscription
                                        </Box>

                                    </Grid>
                                </>
                            )}

                        </Grid>
                        {/* profiles */}
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                gap: 1
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={5.8}
                                sx={{
                                    backgroundColor: theme.palette.basic.white,
                                    padding: "15px",
                                    borderRadius: "12px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "40px",
                                        height: "40px",
                                        mb: 1
                                    }}
                                >
                                    <img
                                        src={starCard}
                                        alt="Number of Profiles"
                                        style={{
                                            width: "110%",
                                            height: "110%",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        gap: "6px"
                                    }}
                                >
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Number of Profiles
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontWeight: "800",
                                        }}
                                    >
                                        {profilesCount}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={5.8}
                                md={5.8}
                                sx={{
                                    backgroundColor: theme.palette.basic.white,
                                    padding: "15px",
                                    borderRadius: "12px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "40px",
                                        height: "40px",
                                        mb: 1
                                    }}
                                >
                                    <img
                                        src={starCard}
                                        alt="Number of Profiles"
                                        style={{
                                            width: "110%",
                                            height: "110%",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        gap: "6px"
                                    }}
                                >
                                    <Typography
                                        variant="body3"
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Connected Profile
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontWeight: "800",
                                        }}
                                    >
                                        {data?.profile?.tag_name?.replace(/\b\w/g, (char) => char.toUpperCase())}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>)
                    : (
                        <Grid
                            sx={{
                                backgroundColor: theme.palette.primary.skyBlue,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "12px",
                                p: 1.5
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "8px",
                                    fontWeight: "700",
                                    fontSize: "12"
                                }}
                            >
                                <FaCalendar color={theme.palette.primary.main} size={20} />
                                Created Date :   {formattedDate(data?.created_at)}
                            </Typography>
                        </Grid>
                    )
                }
                {/* Qr */}
                <Grid
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                        mt: 3
                    }}
                >
                    {/* QR Code Image */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundImage: `url(${loadingImg})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            padding: "10px",
                            borderRadius: "34px",
                            width: "140px",
                            height: "140px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",

                        }}
                    >
                        <img
                            src={data?.url}
                            alt="No QR Code"
                            style={{
                                width: "160px",
                                height: "160px",
                                backgroundSize: "cover",
                            }}
                        />
                    </Box>

                    {/* Link */}
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            backgroundColor: "#E5F0FF",
                            padding: "4px 12px",
                            borderRadius: "20px",
                        }}
                    >
                        <ImLink
                            color={theme.palette.primary.main}
                            size={20}
                            style={{
                                transform: "rotate(90deg)",
                            }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                fontWeight: "bold",
                                color: "#000",
                            }}
                        >
                            <span
                                onClick={() => {
                                    const displayedUrl = data?.url;
                                    navigator.clipboard
                                        .writeText(displayedUrl)
                                        .then(() => {
                                            alert("Link copied to clipboard!");
                                        })
                                        .catch((error) => {
                                            console.error("Failed to copy text:", error);
                                        });
                                }}
                                style={{
                                    cursor: "pointer",
                                    width: "60px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                }}
                                title={data?.url}
                            >
                                {data?.url}
                            </span>
                        </Typography>
                    </Box>
                </Grid>

            </DialogContent>


        </Dialog >
    );
};

export default ViewCard;
