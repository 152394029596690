export const formattedDate = (inputDate) => {
    // Check if the inputDate is valid and not null/undefined
    if (!inputDate) {
        // console.error("Invalid date input: ", inputDate);
        return "Invalid Date";
    }

    // Try parsing the date
    const date = new Date(inputDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        console.error("Invalid date format: ", inputDate);
        return "Invalid Date";
    }

    // Format the valid date
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
};
