import React, { useEffect, useRef } from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import colorConfigs from '../../configs/colorConfigs';
import InputField from "../../components/InputField"
import { FiSearch } from "react-icons/fi";
import DynamicTable from "../../components/common/table/DynamicTable";
import { useState } from "react";
import { message, Switch } from "antd";
import { LuEye } from "react-icons/lu";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import Status from "../../components/common/status/status";
import AddButton from '../../components/common/Buttons/AddButton';
import NewCard from './GenerateNewCard';
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useDispatch, useSelector } from 'react-redux';
import { setCardsData, setCardCounters } from '../../redux/features/appStateSlice';
import supabase from "../../configs/supabase";
import DynamicFilter from '../../components/common/filter/DynamicFilter';
import theme from '../../configs/theme';
import ViewCard from './ViewCard';
import DeleteItemDialog from '../../components/common/DeleteItem';
import { formattedDate } from "../../configs/formate"
import AllCards from '../../assets/svg/icons/cards/AllCards';
import NewCards from '../../assets/svg/icons/cards/NewCards';
import ActiveCards from '../../assets/svg/icons/cards/ActiveCards';
import InactiveCards from '../../assets/svg/icons/cards/InactiveCards';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const hoverEffectStyle = {
    cursor: "pointer",
    borderRadius: 25,
    height: '60px',
    width: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'scale(1.2)',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    }
};

const style = {
    color: "#172B4D",
    fontSize: "14.5px",
    fontWeight: "600",
    lineHeight: "20px",

};

function Cards() {

    const cardsData = useSelector((state) => state.appState.cards);
    const cardCounters = useSelector((state) => state.appState.cardCounters);
    const [addNewCard, setAddNewCard] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState(cardsData);
    const [cardsCount, setCardsCount] = useState(cardCounters?.all || 0);
    const [activeCardsCount, setActiveCardsCount] = useState(cardCounters?.active || 0);
    const [lastWeekcardsCount, setLastWeekCardsCount] = useState(cardCounters?.week || 0);
    const [newMail, setNewMail] = useState(false);
    const [updatingRowId, setUpdatingRowId] = useState(null);
    const [openViewCard, setOpenViewCard] = useState(false);
    const [data, setData] = useState({});
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    const [deletedItemId, setDeletedItemId] = useState();
    const [profilesCount, setProfilesCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        status: null,
        cardStatus: null,
        createdDate: null,
    });
    dayjs.extend(utc);

    const items = [
        { title: 'All Cards', value: cardsCount, icon: <AllCards sx={{ size: "50px" }} /> },
        { title: 'New Cards', value: lastWeekcardsCount, icon: <NewCards /> },
        { title: 'Active Cards', value: activeCardsCount, icon: <ActiveCards /> },
        { title: 'Inactive Cards', value: cardsCount - activeCardsCount, icon: <InactiveCards /> }
    ];

    const currentPage = useRef(1);
    const currentLimit = useRef(5);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();

    const handleOpenDialog = () => {
        setAddNewCard(true);
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleCloseDialog = () => {
        setAddNewCard(false);
        setOpenViewCard(false)
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);



    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        currentPage.current = newPage;
    };

    const handleRowsChange = (rows) => {
        currentPage.current = 1;
        currentLimit.current = rows;
        setRowsPerPage(rows);
        setPage(1);
    };

    const handleDeleteConfirmation = () => {
        DeleteCard(deletedItemId);
    };

    const DeleteCard = (id) => {
        setLoading(true);
        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${API_URL}/Physical-Card/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                setNewMail(!newMail)
                setLoading(false);
                setDeleteConfirmationDialogOpen(false)
                message.success("Card was deleted successfully.");
            })
            .catch((error) => {
                console.log("Error delete data: ", error);
                message.error("Error delete data.");
                setLoading(false);
            });
    };

    const getProfilesCountByUserId = async (userId) => {
        try {
            const { data, error, count } = await supabase
                .from('card')
                .select('*', { count: 'exact' })
                .eq('user_id', userId);

            if (error) throw error;

            await setProfilesCount(count);
            setOpenViewCard(true)
        } catch (error) {
            console.error("Error fetching cards data:", error.message);
            setLoading(false);
            return;
        }
    };

    const handleClearFilter = () => {
        handleFilterChange(null)
    };

    const handleFilterChange = (data) => {

        if (data === null) {
            setFilters({
                createdDate: null,
                status: null,
                cardStatus: null
            });
        } else {
            const { 'Card Status': cardStatus, 'Status': status, 'Created Date': createdDate } = data;


            setFilters((prevFilters) => ({
                ...prevFilters,
                cardStatus: cardStatus || prevFilters.cardStatus,
                status: status || prevFilters.status,
                createdDate: createdDate ? dayjs(createdDate).local().format('YYYY-MM-DD') : prevFilters.createdDate
            }));
        }

    };
    const addOneDay = (dateStr) => {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Adds one day
        return date.toISOString().split('T')[0]; // Returns the date in 'YYYY-MM-DD' format
    }

    useEffect(() => {
        const getCards = async () => {
            setLoading(true);

            // Calculate pagination range
            const from = (currentPage.current - 1) * currentLimit.current;
            const to = currentPage.current * currentLimit.current - 1;

            // Calculate the date for one week ago
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

            try {
                // 1. Retrieve data with relationships and apply filters
                let query = supabase
                    .from("physical_card")
                    .select(
                        "* , user:user_id(full_name,user_name,profile_picture,email,created_at,id,subscription:current_subscription_id(created_at,subscription_id(name,billing_frequency))),profile:profile_id(tag_name)",
                        { count: "exact" }
                    )
                    .range(from, to).order('created_at', { ascending: false });

                // Apply filters directly on the database query


                if (filters.status !== null) {
                    if (filters.status === "active") {
                        query = query.not("profile_id", "is", null); // Active cards: filter by profile_id not being null
                    } else if (filters.status === "inactive") {
                        query = query.is("profile_id", null); // Inactive cards: filter by profile_id being null
                    }
                }

                if (filters.cardStatus !== null) {
                    query = query.eq("is_enabled", filters.cardStatus === "enabled"); // Filter by card status (enabled/disabled)
                }

                if (filters.createdDate !== null) {
                    query = query.gte("created_at::date", `${filters.createdDate} 00:00:00`);
                    query = query.lt("created_at::date", `${addOneDay(filters.createdDate)} 00:00:00`);
                }

                // Execute the query
                const { data, error } = await query;

                if (error) {
                    console.error("Error fetching cards data:", error.message);
                    setLoading(false);
                    return;
                }

                // Apply search locally on the retrieved data
                let filteredData = data || [];
                if (data && searchTerm) {
                    filteredData = filteredData.filter(card =>
                        card.user?.full_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        card.id?.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                }

                setCards(filteredData);
                dispatch(setCardsData(filteredData));

            } catch (error) {
                console.error("Unexpected error:", error.message);
            } finally {
                setLoading(false); // Set loading state to false
            }
        };

        // Separate function to calculate statistics
        const calculateStatistics = async () => {
            try {
                const oneWeekAgo = new Date();
                oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

                // Perform queries for statistics
                const [lastWeekData, activeData, totalData] = await Promise.all([
                    supabase
                        .from("physical_card")
                        .select("id", { count: "exact" })
                        .gte("created_at", oneWeekAgo.toISOString()), // Count cards created in the last week
                    supabase
                        .from("physical_card")
                        .select("id", { count: "exact" })
                        .not("profile_id", "is", null), // Count active cards (profile_id not null)
                    supabase
                        .from("physical_card")
                        .select("id", { count: "exact" }), // Count all cards
                ]);

                // Update local state for statistics
                setCardsCount(totalData.count || 0);
                setLastWeekCardsCount(lastWeekData.count || 0);
                setActiveCardsCount(activeData.count || 0);

                // Update Redux store with statistics
                dispatch(setCardCounters({
                    all: totalData.count || 0,
                    active: activeData.count || 0,
                    week: lastWeekData.count || 0,
                }));
            } catch (error) {
                console.error("Error calculating statistics:", error.message);
            }
        };

        // Call both functions separately
        getCards();
        calculateStatistics();
    }, [searchTerm, filters, currentPage.current, currentLimit.current, newMail]);




    const handleEnableDisableChange = async (id, value) => {
        setUpdatingRowId(id);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/Physical-Card/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { is_enabled: value }
        };
        axios
            .request(config)
            .then((response) => {
                setNewMail(!newMail)
                setUpdatingRowId(null);
            })
            .catch((error) => {
                console.log("Error update data: ", error);
            });
    }
    const columns = [
        {
            field: "id",
            headerName: "ID",
            flex: 1,
            style: style,
            minWidth: "50px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    <span>{params?.row?.id.slice(0, 8)}</span>
                </Box>
            ),
        },
        {
            field: "url",
            headerName: "Link",
            style: style,
            flex: 1,
            minWidth: "100px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <span
                        onClick={() => {
                            const displayedUrl = params?.row?.url;
                            navigator.clipboard
                                .writeText(displayedUrl)
                                .then(() => {
                                    alert("Link copied to clipboard!");
                                })
                                .catch((error) => {
                                    console.error("Failed to copy text:", error);
                                });
                        }}
                        style={{
                            cursor: "pointer",
                            width: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            display: "inline-block",
                        }}
                        title={params?.row?.url}
                    >
                        {params?.row?.url}
                    </span>
                </Box>
            ),
        },
        {
            style: style,
            field: "userName",
            headerName: "User Name",
            flex: 1,
            minWidth: "150px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <span style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'start',
                }}

                >
                    {params?.row?.user_id === null ? (
                        <span>_</span>
                    ) : (
                        <>
                            <img
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    marginRight: "5px"
                                }}
                                src={params?.row?.user?.profile_picture && params?.row?.user?.profile_picture?.length > 10 ? params?.row?.user?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                alt=""
                            />
                            <span>{params?.row?.user?.full_name} </span>
                        </>

                    )}

                </span>
            ),
        },
        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            style: style,
            minWidth: "150px",
            sort: true,
            align: "left",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box>
                    <span>{formattedDate(params?.row?.created_at)}</span>
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: "100px",
            style: style,
            sort: true,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                params?.row?.profile_id === null ? (
                    <Status status={"inactive"} />
                ) : (
                    <Status status={"active"} />
                )
            ),
        },
        {
            field: "is_enabled",
            headerName: "Enable/Disable",
            flex: 1,
            style: style,
            minWidth: "100px",
            sort: true,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Switch
                        style={{
                            backgroundColor: params?.row?.is_enabled ? "#0F5FCB" : "",
                        }}
                        checked={params?.row?.is_enabled}
                        onChange={(value) => {
                            handleEnableDisableChange(params?.row?.id, value)
                        }}
                        loading={updatingRowId === params?.row?.id}
                        disabled={updatingRowId === params?.row?.id}
                    />
                </div>
            ),
        },
        {
            field: "action",
            style: style,
            headerName: "Action",
            align: "center",
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Box
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <LuEye size="22.5px" style={{ color: "#16151C", cursor: "pointer" }}
                        onClick={() => {
                            setData(params?.row)
                            if (params?.row?.user && params?.row?.user?.id) {
                                getProfilesCountByUserId(params?.row?.user?.id)
                            } else {
                                setOpenViewCard(true)
                            }
                        }}

                    />
                    {params?.row?.profile_id !== null && (
                        <RiDeleteBin5Line size="22.5px" style={{ color: "#16151C", cursor: "pointer" }}
                            onClick={() => {
                                setDeleteConfirmationDialogOpen(true)
                                setDeletedItemId(params?.row?.id)
                            }}
                        />
                    )}

                </Box>
            ),
        },
    ];


    const filterData = {

        'Status': [
            {
                name: 'Status',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'inactive' },
                ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Card Status': [
            {
                name: 'Card Status',
                gridWidth: { xs: 12, sm: 12 },
                list: [
                    { label: 'Enabled', value: 'enabled' },
                    { label: 'Disabled', value: 'disabled' },
                ],
                type: 'radio', // Displays as a horizontal checkbox
                direction: 'horizontal',
                placeholder: 'Do you accept the terms and conditions?',
            },
        ],
        'Created Date': [
            {
                name: 'Created Date',
                gridWidth: { xs: 12, sm: 12 },
                type: 'date',
                placeholder: 'Select Date',
            },
        ]

    };

    return (
        <Box>
            {/* topside */}
            <Box>
                {/* titel and add btn */}
                <Box
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    style={{
                        display: "flex",
                        gap: "14px",
                        marginTop: "-130px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "6px",
                    }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: 'row',
                        gap: "14px",
                    }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontFamily: "TT Drugs Trial",
                            fontSize: "24px",
                            fontWeight: "700",
                            lineHeight: "33px",
                            marginLeft: "10px"
                        }}>
                            All Cards
                        </span>
                    </Box>
                    <AddButton
                        onClick={() => {
                            handleOpenDialog(true)
                        }}
                    >
                        +{" "}{"Generate New Card"}
                    </AddButton>

                </Box>
            </Box>
            {/* cards */}
            <Box
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: "8px",
                    gap: 2,
                    p: 1
                }}
            >
                {items?.map((item, index) => (
                    <Box key={index} xs={12} sm={6} md={3} lg={3} sx={{ width: { xs: '100%', sm: '48%', md: '23%' } }}>
                        <Card
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 2,
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 4px',
                                borderRadius: "8px",
                                gap: 2,
                            }}
                        >
                            <CardContent
                                sx={{
                                    width: item.icon ? '70%' : '100%',
                                    textAlign: 'left',
                                    p: 0
                                }}
                            >
                                <Typography sx={{ color: '#172B4D', fontWeight: 800, fontSize: "14px", lineHeight: "18px" }} component="div">
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: colorConfigs.sidebar.active, fontWeight: 800, fontSize: "24px", lineHeight: "30.24px", mt: 1, ml: .5 }} component="div">
                                    {item.value}
                                </Typography>
                            </CardContent>

                            {item.icon ? (
                                <Box
                                // sx={{ ...hoverEffectStyle }}
                                >
                                    {item.icon}
                                </Box>
                            ) : (
                                <Box>
                                </Box>
                            )}
                        </Card>
                    </Box>
                ))}
            </Box>
            {/* search and filter */}
            <Grid container
                spacing={1}
                style={{
                    paddingLeft: "10px",
                    marginTop: "10px",
                    marginLeft: "0",
                    backgroundColor: theme.palette.basic.white,
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px"

                }}>

                <Grid item xs={12} md={3} display={"flex"} alignItems={"center"}
                >
                    <Typography variant='h6'
                        style={{
                            color: theme.palette.secondary.main,
                            fontWeight: "700",
                        }}>    All Cards
                    </Typography>
                </Grid>
                <Grid item xs={12} md={9} display={"flex"} gap={"9px"} alignItems={"end"} justifyContent={"end"} style={{ padding: "15px 10px 15px 0" }}>

                    <Grid item xs={12} md={5}  >
                        <InputField
                            id="Search"
                            placeholder="Search by User Name or Card ID."
                            type="text"
                            value={searchTerm}
                            onChange={(e) => handleSearch(e.target.value)}
                            startAdornment={<FiSearch size={24} color="#88939D" />}
                            variant="outlined"
                            sx={{
                                fontWeight: "500",
                                fontSize: "17px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} style={{ maxWidth: "fit-content" }}>
                        <DynamicFilter
                            width={'400px'}
                            onApply={(data) => handleFilterChange(data)}
                            filterData={filterData}
                            mainColor={colorConfigs.sidebar.active}
                            inputsStyle={{ borderRadius: '10px', height: '45px', fontSize: '16px' }}
                            onClear={handleClearFilter}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <DynamicTable
                columns={columns}
                // data={data}
                data={cards}
                // count={data?.length}
                count={cardsCount}
                page={page}
                rowsPerPage={rowsPerPage}
                pageChange={handlePageChange}
                rowsChange={handleRowsChange}
                pagetype="cards"
            />

            <NewCard addNewCard={addNewCard} onClose={handleCloseDialog} setNewMail={setNewMail} newMail={newMail} />
            <ViewCard openViewCard={openViewCard} onClose={handleCloseDialog} data={data} profilesCount={profilesCount} />
            <DeleteItemDialog
                open={deleteConfirmationDialogOpen}
                onClose={() => setDeleteConfirmationDialogOpen(false)}
                onDelete={handleDeleteConfirmation}
                title={"Delete Card"}
                description={"Are you sure you want to delete this Card ?"}
                loading={loading}
            />
        </Box>

    );
}

export default Cards;
