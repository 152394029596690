import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={36}
        fill="none"
        {...props}
    >
        <path
            fill="#102137"
            d="M35.333 9.75H8.667C7.194 9.75 6 10.925 6 12.375v.875h32v-.875c0-1.45-1.194-2.625-2.667-2.625ZM6 31.625c0 1.45 1.194 2.625 2.667 2.625h26.666c1.473 0 2.667-1.175 2.667-2.625V15H6v16.625ZM26.444 18.5h7.112c.488 0 .888.394.888.875s-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.481.4-.875.888-.875Zm0 3.5h7.112c.488 0 .888.394.888.875s-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.481.4-.875.888-.875Zm0 3.5h7.112c.49 0 .888.392.888.875s-.4.875-.888.875h-7.112a.882.882 0 0 1-.888-.875c0-.483.4-.875.888-.875Zm-10.666-7c1.964 0 3.555 1.567 3.555 3.5s-1.591 3.5-3.555 3.5c-1.964 0-3.556-1.567-3.556-3.5s1.595-3.5 3.556-3.5Zm-3.556 8.75h7.111c1.473 0 2.667 1.175 2.667 2.625a.882.882 0 0 1-.889.875H10.444a.883.883 0 0 1-.888-.875c0-1.45 1.193-2.625 2.666-2.625Z"
        />
        <circle cx={7.864} cy={11.977} r={6.938} fill="#49B43F" />
    </svg>
)
const ActiveCards = memo(SvgComponent)
export default ActiveCards
