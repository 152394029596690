import React from "react";
import { HiUsers } from "react-icons/hi";
import CustomSubscriptionIcon from "../components/customeIcons/CustomSubscriptionIcon";
import Users from "../pages/users/Users";
import Cards from "../pages/cards/Cards";
import Subscription from "../pages/subscription/Subscription";
import { IoIdCardOutline } from "react-icons/io5";


export const appRoutes = [
    {
        path: "/",
        element: <Subscription />,
        state: "subscription",
    },
    {
        path: "/users",
        element: <Users />,
        state: "users",
        sidebarProps: {
            displayText: "Users",
            icon: <HiUsers size={"25px"} color="white" />,
            activeIcon: (
                <HiUsers
                    size={"25px"}
                />
            ),
        },
    },

    {
        path: "/subscription",
        element: <Subscription />,
        state: "subscription",
        sidebarProps: {
            displayText: "Subscription",
            icon: <CustomSubscriptionIcon size={"25px"} active={false} />,
            activeIcon: (
                <CustomSubscriptionIcon active={true}
                    size={"25px"}
                />
            ),
        },
    },
    {
        path: "/cards",
        element: <Cards />,
        state: "cards",
        sidebarProps: {
            displayText: "Cards",
            icon: <IoIdCardOutline size={"25px"} color="white" />,
            activeIcon: (
                <IoIdCardOutline
                    size={"25px"}
                />
            ),
        },
    },
];

export default appRoutes;
