import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import supabase from "../../configs/supabase";
import InputField from "../../components/InputField";
import BlueButton from "../../components/common/Buttons/BlueButton";
import loginImg from "../../assets/images/loginImg.png";
import { RESET_PASSWORD_URL } from "../../utils/constants"


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Email must be a valid format."
        )
        .required('Email is required'),
});

const ForgetPassword = ({ onToggleLogin }) => {
    const theme = useTheme();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const checkEmailExists = async (email) => {
        try {
            const { data, error } = await supabase
                .from('users')
                .select('id')
                .eq('email', email)
                .eq('user_type', 'admin')
                .single();

            if (error || !data) {
                return false;
            }
            return true;
        } catch (err) {
            return false;
        }
    };


    const handleResetPassword = async (values) => {
        setIsSubmitting(true);
        setError('');
        setSuccess('');

        const emailExists = await checkEmailExists(values.email);
        if (!emailExists) {
            message.error('Email does not exist in our admin records. Please try again.');
            setIsSubmitting(false);
            return;
        }
        // const redirectToUrl = `http://localhost:3001/createNewPassword`;
        const redirectToUrl = `${RESET_PASSWORD_URL}/createNewPassword`;
        const { error } = await supabase.auth.resetPasswordForEmail(values.email, {
            redirectTo: redirectToUrl,
        });

        if (error) {
            setError(error.message);
        } else {
            message.success('Email send successfully. Please check your email to reset your password.');
            onToggleLogin();
        }

        setIsSubmitting(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "normal",
                },
                justifyContent: "center",
                height: "100vh",
                gap: "50px"
            }}
        >
            {/* Left Side - Image */}
            <Box
                sx={{
                    flex: 1,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                    },
                    justifyContent: "end",
                    alignItems: "center",
                }}
            >
                <img
                    src={loginImg}
                    alt="App Showcase"
                    style={{
                        width: "94%",
                        // maxWidth: "470px",
                        height: "95%",
                        objectFit: "cover",
                        borderRadius: "20px"

                    }}
                />
            </Box>

            {/* Right Side - Forgot Password Form */}
            <Box
                sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "start",
                    },
                    justifyContent: "center",
                    padding: "3rem",
                }}
            >
                <Button
                    onClick={onToggleLogin}
                    sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "40px",
                        marginBottom: "1rem",
                        color: "#16151C",
                        marginLeft: "-15px",
                        borderRadius: "25px"
                    }}
                >
                    <IoIosArrowBack size={22} style={{ paddingRight: "5px" }} /> Back
                </Button>
                <Box sx={{ textAlign: "start", mb: 3 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "800",
                            fontSize: "30px",
                            lineHeight: "40px",
                            color: theme.palette.text.lightBlack,
                        }}
                    >
                        Forgot Password
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.steelBlue,
                            fontWeight: "600",
                            lineHeight: "25px",
                            mt: 1,
                        }}
                    >
                        Enter your registered email address.
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.steelBlue,
                            fontWeight: "600",
                            lineHeight: "25px",

                        }}
                    >
                        We'll send you a code to reset your password.
                    </Typography>
                </Box>

                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => handleResetPassword(values)}
                >
                    {({ errors, touched, values, handleChange, handleBlur }) => (
                        <Form
                            style={{
                                width: "100%",
                                maxWidth: "400px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                            }}
                        >
                            {/* Email Field */}
                            <InputField
                                id="email"
                                label="Email Address"
                                placeholder="robertallen@example.com"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                required
                                fullWidth
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    "& .MuiFormLabel-root": {
                                        fontWeight: "600",
                                        color: theme.palette.primary.main
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: theme.palette.primary.main,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: theme.palette.primary.main,
                                    },

                                }}

                            />

                            {/* Reset Password Button */}
                            <BlueButton
                                type="submit"
                                sx={{
                                    fontSize: "20px",
                                    width: "100%"
                                }}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Reset Password"}
                            </BlueButton>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default ForgetPassword;

