import React from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Button,
} from "@mui/material";
import deletee from "../../assets/images/delete.png";


const DeleteItemDialog = ({
    open,
    onClose,
    onDelete,
    loading = false,
    title = "Delete Item",
    description = "Are you sure you want to delete this Item?",
    deleteImage = deletee,
}) => {
    return (
        <Dialog
            style={{
                zIndex: 10,
                width: "100%",
            }}
            open={open}
            onClose={onClose}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: { borderRadius: "30px", width: "400px" },
            }}
        >
            <DialogContent style={{ padding: "30px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {deleteImage && <img src={deleteImage} alt="Delete Confirmation" />}
                </div>
                <DialogContentText sx={{ textAlign: "center" }}>
                    <Typography
                        component="span"
                        variant="h6"
                        style={{ fontWeight: "bold", color: "black", textAlign: "center" }}
                    >
                        {title}
                    </Typography>
                </DialogContentText>
                <Typography
                    style={{ textAlign: "center" }}
                >{description}</Typography>
            </DialogContent>

            <DialogActions
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBottom: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                <Button
                    variant="outlined"
                    style={{
                        color: "black",
                        borderColor: "lightgray",
                        width: "100%",
                        borderRadius: "8px",
                        textTransform: "capitalize"
                    }}
                    onClick={onClose}
                    disabled={loading}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    style={{
                        background: "var(--Error, #D50000)",
                        color: "white",
                        width: "100%",
                        borderRadius: "8px",
                        textTransform: "capitalize"

                    }}
                    onClick={onDelete}
                    disabled={loading}
                >
                    {loading ? "Deleting..." : "Yes, Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteItemDialog;
