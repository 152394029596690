import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from "@mui/material";
import theme from "../..//configs/theme";
import WhiteButton from "../../components/common/Buttons/WhiteButton";
import BlueButton from "../../components/common/Buttons/BlueButton";
import { IoMdMail } from "react-icons/io";
import { useSelector } from "react-redux";
import { API_URL } from "../../utils/constants";
import axios from "axios";
import { message } from "antd";

const EditSubscription = ({ editSubscription, onClose, dataToEdit, getSubscriptions, subscriptionTypes }) => {
    const [loading, setLoading] = useState(false);
    const userData = useSelector((state) => state.appState.userData);



    const [newSubType, setnewSubType] = useState(subscriptionTypes[0]?.id);

    useEffect(() => {
        if (subscriptionTypes && subscriptionTypes?.length > 0) {
            const matchingType = subscriptionTypes?.find(type => type?.id === dataToEdit?.subscription_id);
            if (matchingType) {
                setnewSubType(matchingType?.id);
            } else {
                setnewSubType(subscriptionTypes[0]?.id);
            }
        } else {
            setnewSubType("noData");
        }
    }, [subscriptionTypes, dataToEdit]);

    const handleChange = (e) => {

        setnewSubType(e.target.value);
    };

    const handleSave = () => {
        newSubType && userData?.id && EditSubscription()
    };
    const EditSubscription = async () => {
        setLoading(true);
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${API_URL}/subscription/${dataToEdit?.id}
            
            `,
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                "performed_by": userData?.id,
                "subscription_id": newSubType
            }
        };
        axios
            .request(config)
            .then((response) => {
                getSubscriptions()
                setLoading(false);
                onClose();
                message.success("The type was changed successfully.");
            })
            .catch((error) => {
                console.log("Error Edit Type: ", error);
                message.error("Error Edit Type.");
                setLoading(false);
                onClose();
            });
    }

    const handleCancel = () => {
        setnewSubType("noData");
        onClose();
    };

    return (
        <Dialog
            open={editSubscription}
            onClose={handleCancel}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    padding: "20px",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: '800',
                    fontSize: "20px",
                }}
            >
                Edit Subscription
            </DialogTitle>
            <DialogContent >
                <Grid container sx={{ gap: 3 }}>
                    {/* User Info: Image, Name, and Email */}
                    <Grid item xs={12} md={12} container
                        sx={{
                            backgroundColor: theme.palette.primary.skyBlue,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "12px",
                            p: 1.5
                        }}
                    >
                        {/* User Image */}
                        <Grid
                            item
                            xs={12}
                            sm={2.5}
                            md={2.5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                ml: -1.5,
                            }}
                        >
                            <Box
                                sx={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: "4px",
                                    overflow: "hidden",
                                    border: "2px solid #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",

                                }}
                            >
                                <img
                                    style={{
                                        width: 70,
                                        height: 70,
                                        borderRadius: "4px",
                                    }}
                                    src={dataToEdit?.user?.profile_picture && dataToEdit?.user?.profile_picture?.length > 10 ? dataToEdit?.user?.profile_picture : "https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg"}
                                    alt=""
                                />
                            </Box>
                        </Grid>

                        {/* User Name and Email */}
                        <Grid item xs={12} md={9.5} sm={9.5} >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: "bold",
                                    textAlign: { xs: "center", sm: "left" },
                                }}
                            >
                                {dataToEdit?.user?.full_name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: { xs: "center", sm: "left" },
                                    display: "flex",
                                    justifyContent: { xs: "center", sm: "left" },
                                    alignItems: "start",
                                    gap: "8px"
                                }}
                            >
                                <IoMdMail color={theme.palette.primary.main} size={20} />
                                {dataToEdit?.user?.email}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Subscription Dropdown */}
                    <Grid item xs={12} md={12}>
                        <FormControl
                            sx={{
                                minWidth: "100%",
                                marginTop: { xs: "10px", sm: "1px" },
                            }}
                        >
                            <InputLabel
                                required
                                sx={{
                                    color: theme.palette.text.steelBlue,
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                    "&.Mui-focused": {
                                        color: theme.palette.text.steelBlue,
                                    },
                                }}
                            >
                                New Subscription Type
                            </InputLabel>
                            <Select
                                name="subscription Type"
                                label="NewSubscriptionTypeType"
                                value={newSubType}
                                onChange={handleChange}
                                sx={{
                                    width: "100%",
                                    height: "52px",
                                    fontSize: "16px",
                                    color: theme.palette.secondary.main,
                                    fontWeight: "500",
                                    lineHeight: "25px",
                                    borderRadius: "8px",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#88939D",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#88939D",
                                    },
                                }}
                                disabled={subscriptionTypes && subscriptionTypes?.length <= 0}
                            >
                                {subscriptionTypes && subscriptionTypes?.length <= 0 && (
                                    <MenuItem key={"testid"} value={"noData"}> No plans are available yet, but check back soon!</MenuItem>
                                )}
                                {subscriptionTypes && subscriptionTypes?.length > 0 && subscriptionTypes?.map((type) => (
                                    <MenuItem key={type?.id} value={type?.id}>
                                        {type?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    justifyContent: "end",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: "10px", sm: "0" },
                    mr: 2,
                    ml: 2,
                }}
            >
                <WhiteButton
                    sx={{
                        width: { xs: "100%", sm: "140px" },
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </WhiteButton>
                <BlueButton
                    sx={{
                        width: { xs: "100%", sm: "140px" },
                    }}
                    onClick={handleSave}
                >
                    {loading ? "Loading..." : "save"}
                </BlueButton>

            </DialogActions>
        </Dialog >
    );
};

export default EditSubscription;
