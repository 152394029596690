import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testRedux: "test11",
  openDrawer: true,
  selected: "",
  appState: "",
  userData: {},
  subTypes: [],
  subs: [],
  users: [],
  cards: [],
  cardCounters: {
    all: 0,
    active: 0,
    week: 0
  },
  usersCounters: {
    activeUsers: 0,
    inactiveUsers: 0,
    subscriptionUsers: 0,
    totalUsers: 0
  },
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setTestRedux: (state, action) => {
      state.testRedux = action.payload;
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSubTypes: (state, action) => {
      state.subTypes = action.payload;
    },
    setSubs: (state, action) => {
      state.subs = action.payload;
    },
    setUsersData: (state, action) => {
      state.users = action.payload;
    },
    setCardCounters: (state, action) => {
      state.cardCounters = action.payload;
    },
    setUsersCounters: (state, action) => {
      state.usersCounters = action.payload;
    },
    setCardsData: (state, action) => {
      state.cards = action.payload;
    },


  },
});

export const {
  setUserData,
  setTestRedux,
  setOpenDrawer,
  setSelected,
  setAppState,
  setSubTypes,
  setSubs,
  setUsersData,
  setCardsData,
  setCardCounters,
  setUsersCounters,

} = appStateSlice.actions;

export default appStateSlice.reducer;
