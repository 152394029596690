import { Button, styled } from '@mui/material';
import theme from '../../../configs/theme';

const WhiteButton = styled(Button)(() => ({
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    borderRadius: '10px',
    height: '48px',
    width: '140px',
    fontWeight: '600',
    fontSize: "15px",
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'capitalize',
    '&:hover': { backgroundColor: theme.palette.hover.lightLavenderGray, },

}));

export default WhiteButton