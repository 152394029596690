import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuth = localStorage.getItem("isAuth") || sessionStorage.getItem("isAuth");
  const location = useLocation();


  if (!isAuth) {
    if (location.pathname !== "/login") {
      if (location.pathname === "/createNewPassword") {
        return children;
      } else {
        return <Navigate to="/login" />;
      }
    }
  } else {
    if (location.pathname === "/login" || location.pathname === "/createNewPassword") {
      return <Navigate to="/subscription" />;
    }
  }

  return children;

};

export default ProtectedRoute;
