import * as React from "react"
import { memo } from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={38}
        height={37}
        fill="none"
        {...props}
    >
        <path
            fill="#102137"
            d="M35.333 10.665H8.667C7.194 10.665 6 11.84 6 13.29v.875h32v-.875c0-1.45-1.194-2.625-2.667-2.625ZM6 32.54c0 1.449 1.194 2.625 2.667 2.625h26.666c1.473 0 2.667-1.176 2.667-2.625V15.915H6V32.54Zm20.444-13.125h7.112c.488 0 .888.393.888.875 0 .48-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.482.4-.875.888-.875Zm0 3.5h7.112c.488 0 .888.393.888.875 0 .48-.4.875-.888.875h-7.112a.885.885 0 0 1-.888-.875c0-.482.4-.875.888-.875Zm0 3.5h7.112c.49 0 .888.391.888.875 0 .483-.4.875-.888.875h-7.112a.882.882 0 0 1-.888-.875c0-.484.4-.875.888-.875Zm-10.666-7c1.964 0 3.555 1.567 3.555 3.5 0 1.932-1.591 3.5-3.555 3.5-1.964 0-3.556-1.568-3.556-3.5 0-1.933 1.595-3.5 3.556-3.5Zm-3.556 8.75h7.111c1.473 0 2.667 1.175 2.667 2.625a.882.882 0 0 1-.889.875H10.444a.883.883 0 0 1-.888-.875c0-1.45 1.193-2.625 2.666-2.625Z"
        />
        <circle cx={7.864} cy={12.891} r={6.938} fill="#B43F3F" />
    </svg>
)
const InactiveCards = memo(SvgComponent)
export default InactiveCards
