import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, ListItemButton, ListItemIcon, useTheme } from "@mui/material";


const SidebarItem = ({ item }) => {
  const theme = useTheme();
  const { appState } = useSelector((state) => state.appState);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        "&: hover": {
          fontWeight: '800',
          backgroundColor: theme.palette.basic.white,
          color: theme.palette.primary.main,

        },
        backgroundColor: appState === item.state ? theme.palette.basic.white : "unset",
        borderTopRightRadius: "32px",
        borderBottomRightRadius: "32px",
        paddingY: "12px",
        paddingX: "24px",
        marginRight: "20px",
        marginTop: "5px",
        color: appState === item.state ? theme.palette.primary.main : theme.palette.text.lightGray,
        fontSize: "16px",
        fontWeight: (appState === item.state || isHovered) ? "800" : "500",
        lineHeight: "20px",

      }}
    >
      <Box sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: (appState === item.state || isHovered) ? "10px" : "",
      }}>
        <ListItemIcon
          sx={{
            "&:hover": {
              color: theme.palette.primary.main,
            },
            color: (appState === item.state || isHovered) ? theme.palette.primary.main : theme.palette.text.lightGray,
            marginRight: "-10px"
          }}
        >
          {(appState === item.state || isHovered) && item.sidebarProps.activeIcon
            ? item.sidebarProps.activeIcon
            : item.sidebarProps.icon}
        </ListItemIcon>
        {item.sidebarProps.displayText}
      </Box>
    </ListItemButton>

  ) : null;
};

export default SidebarItem;
